import * as React from 'react';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from 'components/common/ErrorBoundary';
import { Header } from './Header';
import { HelpCenter } from './HelpCenter';
import { Footer } from './Footer';
import { SplashScreen } from './SplashScreen';
import { SgwtConnect } from './SgwtConnect';
import { useAppContext } from 'components/common/AppProvider';
import { FranfinanceSelectors } from 'store/Normalizr/FranfinanceSelectors';
import { createSetClientAction } from 'store/AppContext/AppContextActions';

interface IProps {
    children: React.ReactNode;
}

export const Layout: React.FC<IProps> = ({ children }) => {
    const { dispatch, state: { appContext, entities } } = useAppContext();
    const loggedUser = FranfinanceSelectors.getLoggedUser(appContext.loggedUserId, entities.franFinance);
    const mandates = loggedUser?.mandates;

    React.useEffect(() => {
        if (appContext.currentClient.siren == null && mandates && mandates?.filter(m => m?.clientSiren !== null)?.length > 0) {
            const mandate = mandates?.filter(m => m?.clientSiren !== null).at(0);
            if (mandate?.clientName && mandate.clientSiren) {
                dispatch(createSetClientAction(mandate.clientName, mandate.clientSiren, appContext?.currentClient?.isInternal || false));
            }
        }
    }, [JSON.stringify(appContext)]);

    return (
        <div>
            <Header />
            <main className="bg-lvl2 py-4 position-relative" style={{ minHeight: 'calc(100vh - var(--header-height))' }}>
                <ErrorBoundary>{children}</ErrorBoundary>
            </main>
            <Footer />
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                closeButton={false}
                icon={false}
                newestOnTop
                closeOnClick
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <HelpCenter />
            <SgwtConnect />
            <SplashScreen />
        </div >);
};

import * as React from 'react';
import SocgenLogoFull from 'images/socgen_logo_full.svg';
import SocgenLogoMuted from 'images/socgen_logo_muted.svg';
import Image503 from 'images/503.svg';
import './Maintenance.scss';
import { formatDateWithHour } from 'services/Date';

export const MaintenancePage: React.FC<{ endDate?: Date }> = ({ endDate }) => {
    const currentYear = (new Date()).getFullYear();
    const handleTryAgainClick = () => {
        window.location.reload();
    };
    const formattedEndDate = formatDateWithHour(endDate);

    return <div className="maintenance d-flex flex-column w-100 flex-fill">
        <section className="section-1">
            <div className="container-fluid d-block d-lg-none px-4">
                <div className="row">
                    <div className="col-7 bg-lvl1">
                        <img className="my-4 d-inline-block" alt="Societe Générale" src={SocgenLogoMuted} height="32" />
                    </div>
                    <div className="col-4 bg-lvl1"></div>
                </div>
            </div>
            <div className="container d-none d-lg-block">
                <div className="row">
                    <div className="col-7 bg-lvl1">
                        <img className="my-5 d-inline-block" alt="Societe Générale" src={SocgenLogoFull} height="32" />
                    </div>
                    <div className="col-4 bg-lvl1"></div>
                </div>
            </div>
        </section>
        <section className="section-2">
            <div className="container-fluid">
                <div className="container main-wrapper">
                    <div className="row">
                        <div className="col-lg-2 bg-lvl1 pt-5 pb-4 py-lg-0">
                            <span className="d-block d-md-none text-outline-socgen text-primary-alt fw-normal"
                                style={{ fontSize: '56px', fontFamily: 'Neue Haas Grotesk W04 Disp' }}>
                                503
                            </span>
                            <span className="d-none d-md-block d-lg-none text-outline-socgen text-primary-alt fw-normal"
                                style={{ fontSize: '56px', fontFamily: 'Neue Haas Grotesk W04 Disp', lineHeight: 1.2 }}>
                                503
                            </span>
                            <span className="mt-n2 d-none d-lg-inline-block d-xl-none display-2 text-outline-socgen text-primary-alt fw-normal">
                                503
                            </span>
                            <span className="mt-n3 d-none d-xl-block display-1 text-outline-socgen text-primary-alt fw-normal"
                                style={{ fontSize: '96px', overflow: 'hidden' }}>
                                503
                            </span>
                        </div>
                        <div className="col-lg-5 bg-lvl1 d-flex flex-column justify-content-between">
                            <div>
                                <h1 className="d-none d-lg-block display-2 fw-normal lh-1 pt-0 pb-3">Nous sommes de retour très vite.</h1>
                                <h1 className="d-lg-none display-3 fw-normal lh-1 py-2">Nous sommes de retour très vite.</h1>

                                <p className="d-lg-none mb-5 h3 fw-normal text-secondary lh-1">
                                    <a className="text-link" style={{ cursor: 'pointer' }} onClick={handleTryAgainClick}>Franfinance Entreprises&nsbp;</a>
                                    <span>est en cours de maintenance</span>
                                </p>
                                {endDate && <p className="d-lg-none mb-5 lead fw-normal text-secondary lh-1 display-end-date">
                                    <span>La reprise du service est prévue pour le&nbsp;</span>
                                    <span>{formattedEndDate}</span>
                                </p>}

                                <p className="d-none d-lg-block d-xxl-none display-3 fw-normal text-secondary w-75 lh-1">
                                    <a className="text-link" style={{ cursor: 'pointer' }} onClick={handleTryAgainClick}>Franfinance Entreprises&nbsp;</a>
                                    <span>est en cours de maintenance</span>
                                </p>
                                {endDate && <p className="d-none d-lg-block d-xxl-none lead fw-normal text-secondary w-75 lh-1 display-end-date">
                                    <span>La reprise du service est prévue pour le&nbsp;</span>
                                    <span>{formattedEndDate}</span>
                                </p>}

                                <p className="d-none d-xxl-block display-3 fw-normal text-secondary w-75 lh-1">
                                    <a className="text-link" style={{ cursor: 'pointer' }} onClick={handleTryAgainClick}>Franfinance Entreprises&nbsp;</a>
                                    <span>est en cours de maintenance</span>
                                </p>
                                {endDate && <p className="d-none d-xxl-block lead fw-normal text-secondary w-75 lh-1 display-end-date">
                                    <span>La reprise du service est prévue pour le&nbsp;</span>
                                    <span>{formattedEndDate}</span>
                                </p>}

                            </div>
                            <div className="mb-5 d-none d-md-flex">
                                <button className="btn btn-xl btn-primary application-link" onClick={handleTryAgainClick}>Rééssayer</button>
                                <a className="btn btn-xl btn-secondary mx-2" href="https://www.sgmarkets.com/services">Aller à Mes services</a>
                            </div>
                            <div className="d-block d-md-none">
                                <button className="btn btn-xl btn-primary btn-block mb-1 application-link" onClick={handleTryAgainClick}>Rééssayer</button>
                                <a className="btn btn-xl btn-secondary btn-block mb-1" href="https://www.sgmarkets.com/services">Aller à Mes services</a>
                            </div>
                        </div>
                        <div className="col-4 px-0 position-relative d-none d-lg-block">
                            <img src={Image503} style={{ background: '#fff center no-repeat', backgroundSize: '110%' }} />
                        </div>
                    </div>
                </div>
            </div>

        </section >
        <section className="section-3 flex-grow-1 d-flex">
            <div className="container-fluid">
                <div className="container h-100 d-none d-lg-block">
                    <div className="row h-100">
                        <div className="offset-2 col-5 pt-5 text-muted" style={{ backgroundColor: '#fafafa' }}>
                            © <span>Groupe Société Générale {currentYear}</span>
                        </div>
                    </div>
                </div>
                <footer className="container-fluid d-lg-none position-absolute text-muted" style={{ bottom: 0, left: 0, right: 0, backgroundColor: '#fafafa' }}>
                    <div className="container py-4">
                        © <span>Groupe Société Générale {currentYear}</span>
                    </div>
                </footer>
            </div>
        </section>
    </div>;
};
import { useAppContext } from 'components/common/AppProvider';
import { ServiceLoader } from 'components/common/Loading';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { hasAccess } from 'services/RightsService';
import { sgConnect, redirectUnauthorizedUser } from 'services/SgConnect';
import { getAppContext } from 'store/AppContext/AppContextThunk';
import { FranfinanceSelectors } from 'store/Normalizr/FranfinanceSelectors';
import { RoutePaths } from './RoutePaths';
import { configuration, Environment, getEnvironment } from 'config/constants';
import { getRightToSeeFinancialRequests } from 'store/Financial/FinancialThunk';
import { Utilities } from 'services/ApiService/Franfinance/FranfinanceApiClient';

interface IProtectedRouteProps {
    children: React.JSX.Element;
    CheckFranfinanceAuthorization?: boolean;
    hideInProduction?: boolean;
}

export const ProtectedRoute: React.FC<IProtectedRouteProps> = ({
    children,
    CheckFranfinanceAuthorization = false,
    hideInProduction = false,
}) => {
    const { dispatch, state: { appContext: { loggedUserId, isFetching, currentClient: { siren } }, financial, entities } } = useAppContext();
    const navigate = useNavigate();
    const loggedUser = FranfinanceSelectors.getLoggedUser(loggedUserId, entities.franFinance);
    const hide = hideInProduction ? getEnvironment() === Environment.Production && hideInProduction : false;

    const [originNetwork, setOriginNetwork] = React.useState<OriginNetworkType>();

    useEffect(() => {
        (async () => {
            if (!sgConnect?.isAuthorized()) {
                sgConnect?.requestAuthorization();
            }
            else {
                const appContext = await dispatch(getAppContext());

                fetchIsFromIntranetNetwork()
                    .then(setOriginNetwork);

                const canSeeFinancialRequests = siren && await dispatch(getRightToSeeFinancialRequests(siren)) || false;
                if (hide) {
                    navigate(RoutePaths.Errors.NotFound.url());
                }

                if (loggedUser && loggedUser.isInternal && originNetwork === 'internet') {
                    navigate(RoutePaths.Errors.NotAuthorizedInternet.url());
                }

                if (!hasAccess(appContext?.loggedUser)) {
                    redirectUnauthorizedUser();
                }

                if (CheckFranfinanceAuthorization && siren && !financial?.[siren]?.canSeeFinancialRequests?.isFetching && !canSeeFinancialRequests) {
                    navigate(RoutePaths.Errors.NotAuthorized.url());
                }
            }
        })();
    });

    if (isFetching) {
        return <div className="d-flex justify-content-center"><ServiceLoader /></div>;
    }

    return hasAccess(loggedUser) ? children : null;
};

export type OriginNetworkType = 'intranet' | 'internet';

export type OriginNetworkResponse = {
    'origin-network': OriginNetworkType,
}

export const fetchIsFromIntranetNetwork = async (): Promise<OriginNetworkType> => {
    try {
        const url = `${configuration.baseApiUrls.sgConnect}/network/api/v1/origin`;
        const response = await fetch(url);
        const origin = await Utilities.processResponse<OriginNetworkResponse>(response, false, []);
        return origin['origin-network'];
    } catch {
        return 'intranet'; // default, intranet
    }
};
import { Reducer } from 'store';

export const initialState = {
    environment: undefined as string | undefined | null,
    loggedUserId: undefined as string | undefined | null,
    isFetching: false as boolean,
    didInvalidate: true as boolean,
    currentClient: {
        name: undefined as string | undefined,
        siren: undefined as string | undefined,
        isInternal : undefined as boolean | undefined,
    },
} as const;

export type AppContextState = typeof initialState;

export const AppContextReducer: Reducer<AppContextState> = (state = initialState, action) => {
    switch (action.type) {
        case '@APP_CONTEXT/FETCH_APP_CONTEXT':
            return {
                ...state,
                didInvalidate: true,
                isFetching: true,
            };

        case '@APP_CONTEXT/FETCH_APP_CONTEXT_SUCCESS':
            return {
                ...state,
                didInvalidate: false,
                isFetching: false,
                environment: action.payload.environment,
                loggedUserId: action.payload.loggedUserId,
            };

        case '@APP_CONTEXT/FETCH_APP_CONTEXT_FAILURE':
            return {
                ...state,
                didInvalidate: true,
                isFetching: false,
            };

        case '@APP_CONTEXT/SIGN_OUT':
            return {
                ...state,
                didInvalidate: true,
                isFetching: false,
                loggedUserId: undefined,
            };
        case '@APP_CONTEXT/SET_CLIENT':
            return {
                ...state,
                currentClient: {
                    name: action.payload.clientName,
                    siren: action.payload.siren,
                    isInternal:action.payload.isInternal,
                },
            };

        default:
            return state;
    }
};

import { TFunction } from 'i18next';
import { generatePath } from 'react-router-dom';
import { ConditionState, FinancialType, SearchContractRequest, SearchInvoicesRequest } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { formatDate, isoDateFormat } from 'services/Date';
import { DEFAULT_SEARCH_INVOICES_TAKE } from 'store/Invoices/InvoicesReducer';
import { DEFAULT_SEARCH_CONTRACTS_TAKE } from 'store/Contracts/ContractsReducer';
import { ISimulationListSort } from 'components/Pages/Simulations/SimulationListLayout';
interface ISimulationRequestRoute {
    financialType: FinancialType;
    assetNatureId?: number;
    amount?: number;
    duration?: number;
    conditionState: ConditionState;
    secondHandDate?: number,
    supplierSiren?: string,
    supplierName?: string,
}
export const RoutePaths = {
    Actions: {
        route: '/actions',
        url: () => RoutePaths.Actions.route,
        title: (t: TFunction) => t('menu:Titles.YourActions'),
    },
    Company: {
        Information: {
            route: '/company/information',
            url: () => RoutePaths.Company.Information.route,
            title: (t: TFunction) => t('menu:Titles.Your company information'),
        },
    },
    Projects: {
        route: '/projects',
        url: () => RoutePaths.Projects.route,
        title: (t: TFunction) => t('menu:Titles.YourProjects'),
    },
    CustomerRequests: {
        route: '/customer-requests',
        url: () => RoutePaths.CustomerRequests.route,
        Details: {
            route: '/customer-requests/:caseId',
            url: (caseId: string) => generatePath(RoutePaths.CustomerRequests.Details.route, { caseId: `${cleanUrlParameter(caseId)}` }),
        },
    },
    Invoices: {
        route: '/invoices',
        url: (filters?: Partial<SearchInvoicesRequest>) => RoutePaths.Invoices.route + getQueryParameters({
            skip: filters?.skip,
            take: DEFAULT_SEARCH_INVOICES_TAKE,
            invoiceNb: filters?.invoiceNb,
            contractId: filters?.contractId,
            endDate: filters?.endDate && formatDate(filters.endDate, isoDateFormat) || undefined,
            managementCompany: filters?.managementCompany,
        }),
        title: (t: TFunction) => t('menu:Titles.Billings'),
    },
    Contracts: {
        route: '/contracts',
        url: (filters?: Partial<SearchContractRequest>) => RoutePaths.Contracts.route + getQueryParameters({
            skip: filters?.skip,
            take: filters?.take === DEFAULT_SEARCH_CONTRACTS_TAKE ? undefined : filters?.take,
            search: filters?.businessNumber,
            financialType: filters?.financialType,
            status: filters?.status,
            managementCompany: filters?.managementCompany,
            endDateMin: filters?.endDateMin && formatDate(filters.endDateMin, isoDateFormat) || undefined,
            endDateMax: filters?.endDateMax && formatDate(filters.endDateMax, isoDateFormat) || undefined,
            sortDirection: filters?.sortDirection,
            sortSource: filters?.sortSource,
        }),
        title: (t: TFunction) => t('menu:Titles.YourContracts'),
        Billings: {
            route: '/contracts/:contractId/billings',
            url: (contractId: string, take: number, skip: number) => generatePath(RoutePaths.Contracts.Billings.route, { contractId: `${cleanUrlParameter(contractId)}` })
                + getQueryParameters({ skip: skip, take: take || DEFAULT_SEARCH_INVOICES_TAKE }),
            title: (contractId: string, t: TFunction) => t('menu:Titles.Contract id', { contractId }),
        },
        Details: {
            route: '/contracts/:contractId/details',
            url: (contractId: string) => generatePath(RoutePaths.Contracts.Details.route, { contractId: `${cleanUrlParameter(contractId)}` }),
            title: (contractId: string, t: TFunction) => t('menu:Titles.Contract id', { contractId }),
        },
        CustomerService: {
            route: '/contracts/:contractId/customerService',
            url: (contractId: string) => generatePath(RoutePaths.Contracts.CustomerService.route, { contractId: `${cleanUrlParameter(contractId)}` }),
            title: (contractId: string, t: TFunction) => t('menu:Titles.Client service request', { contractId }),
        },
        CustomerRequests: {
            route: '/contracts/:contractId/customerRequests',
            url: (contractId: string) => generatePath(RoutePaths.Contracts.CustomerRequests.route, { contractId: `${cleanUrlParameter(contractId)}` }),
            title: (contractId: string, t: TFunction) => t('menu:Titles.Client service request', { contractId }),
        },
    },
    Errors: {
        NotAuthorized: {
            route: '/error/not-authorized',
            url: () => RoutePaths.Errors.NotAuthorized.route,
        },
        NotAuthorizedContactUs: {
            route: '/error/not-authorized/contact-us',
            url: () => RoutePaths.Errors.NotAuthorizedContactUs.route,
        },
        NotAuthorizedInternet: {
            route: '/error/not-authorized-internet',
            url: () => RoutePaths.Errors.NotAuthorizedInternet.route,
        },
        NotFound: {
            route: '*',
            url: () => '/error/not-found',
        },
    },
    EInvoices: {
        route: '/e-invoices',
        url: () => RoutePaths.EInvoices.route,
        title: (t: TFunction) => t('menu:Titles.EInvoices'),
    },
    Home: {
        route: '/',
        url: (startGuideTour?: string) => RoutePaths.Home.route + getQueryParameters({ startGuideTour }),
        title: (t: TFunction) => t('menu:Titles.Home'),
    },
    Referencing: {
        route: '/referencing',
        url: () => RoutePaths.Referencing.route,
        title: (t: TFunction) => t('menu:Titles.Referencing'),
    },
    Simulations: {
        Request: {
            route: '/simulations/request',
            url: (simulation?: ISimulationRequestRoute) => RoutePaths.Simulations.Request.route + getQueryParameters({
                ...simulation,
            }),
            title: (t: TFunction) => t('menu:Titles.Simulations'),
        },
        Validation: {
            route: '/simulations/validation',
            url: (simulation?: ISimulationRequestRoute) => RoutePaths.Simulations.Validation.route + getQueryParameters({
                ...simulation,
            }),
            title: (t: TFunction) => t('menu:Titles.Simulations'),
        },
        Validated: {
            route: '/simulations/validated/:requestId',
            url: (requestId: string) => generatePath(RoutePaths.Simulations.Validated.route, { requestId: `${cleanUrlParameter(requestId)}` }),
            title: (t: TFunction) => t('menu:Titles.Simulations'),
        },
        FinancialRequest: {
            route: '/simulations',
            url: (simulationListForm?: ISimulationListSort) => RoutePaths.Simulations.FinancialRequest.route + getQueryParameters({
                ...simulationListForm,
            }),
            title: (t: TFunction) => t('menu:Titles.SimulationsList'),
        },
    },
} as const;

export type RouteParams = {
    Contracts: {
        Billings: ExtractRouteParams<typeof RoutePaths.Contracts.Billings.route, string>,
        Details: ExtractRouteParams<typeof RoutePaths.Contracts.Details.route, string>,
        CustomerService: ExtractRouteParams<typeof RoutePaths.Contracts.CustomerService.route, string>,
        CustomerRequests: ExtractRouteParams<typeof RoutePaths.Contracts.CustomerRequests.route, string>,
    },
    CustomerRequests: {
        Details: ExtractRouteParams<typeof RoutePaths.CustomerRequests.Details.route, string>,
    },
    Errors: {
        NotAuthorized: ExtractRouteParams<typeof RoutePaths.Errors.NotAuthorized.route, string>,
        NotFound: ExtractRouteParams<typeof RoutePaths.Errors.NotFound.route, string>,
    },
    Home: ExtractRouteParams<typeof RoutePaths.Home.route, string>,
    Simulations: {
        Request: ExtractRouteParams<typeof RoutePaths.Simulations.Request.route, string>,
        Validation: ExtractRouteParams<typeof RoutePaths.Simulations.Validation.route, string>,
        Validated: ExtractRouteParams<typeof RoutePaths.Simulations.Validated.route, string>,
    }
};

const getQueryParameters = (parameters: Record<string, string | number | undefined | Date | (string | number | undefined | Date)[]>) => {
    const search = new URLSearchParams(
        Object.entries(parameters)
            .filter(entry => entry[1])
            .map(([key, value]) => ([key, value?.toString() || ''])),
    ).toString();
    return search ? `?${search}` : '';
};

const cleanUrlParameter = (parameter?: string) => {
    if (!parameter) {
        return parameter;
    }

    return encodeURIComponent(parameter);
};


type ExtractRouteOptionalParam<T extends string, U = string | number | boolean> = T extends `${infer Param}?`
    ? { [_ in Param]?: U }
    : T extends `${infer Param}*`
    ? { [_ in Param]?: U }
    : T extends `${infer Param}+`
    ? { [_ in Param]: U }
    : { [_ in T]: U };

/* eslint-disable */
type ExtractRouteParams<T extends string, U = string | number | boolean> = string extends T
    ? { [_ in string]?: U }
    : T extends `${infer _}:${infer ParamWithOptionalRegExp}/${infer Rest}`
    ? ParamWithOptionalRegExp extends `${infer Param}(${infer _})`
    ? ExtractRouteOptionalParam<Param, U> & ExtractRouteParams<Rest, U>
    : ExtractRouteOptionalParam<ParamWithOptionalRegExp, U> & ExtractRouteParams<Rest, U>
    : T extends `${infer _}:${infer ParamWithOptionalRegExp}`
    ? ParamWithOptionalRegExp extends `${infer Param}(${infer _})`
    ? ExtractRouteOptionalParam<Param, U>
    : ExtractRouteOptionalParam<ParamWithOptionalRegExp, U>
    : unknown;

import { ServiceLoader } from 'components/common/Loading';
import { Layout } from 'components/Layout';
import React, { lazy, Suspense, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';
import { RoutePaths } from './RoutePaths';
import { InternetNotAuthorized } from 'components/Pages/Errors/InternetNotAuthorized';
import { AppContextsApi } from 'services/ApiService';
import { MaintenancePage } from 'components/Pages/Maintenance/MaintenancePage';
import { useAppContext } from 'components/common/AppProvider';
import { FranfinanceSelectors } from 'store/Normalizr/FranfinanceSelectors';
import { MaintenanceConfigurationResponse } from 'services/ApiService/Franfinance/FranfinanceApiClient';

const Billings = lazy(() => import('components/Pages/Invoices/InvoicesLayout'));
const CompanyInformation = lazy(() => import('components/Pages/CompanyInformation/CompanyInformationLayout'));
const Contracts = lazy(() => import('components/Pages/Contracts/ContractsLayout'));
const ContractDetails = lazy(() => import('components/Pages/ContractDetails/ContractDetailsLayout'));
const ContractBillings = lazy(() => import('components/Pages/ContractBillings/ContractBillingsLayout'));
const EInvoices = lazy(() => import('components/Pages/EInvoices/EInvoicesLayout'));
const CustomerRequests = lazy(() => import('components/Pages/CustomerRequests/CustomerRequestsLayout'));
const CustomerRequestDetails = lazy(() => import('components/Pages/CustomerRequestDetails/CustomerRequestDetailsLayout'));
const ContractCustomerRequests = lazy(() => import('components/Pages/CustomerService/ContractCustomerRequestsLayout'));
const HomeLayout = lazy(() => import('components/Pages/Home/HomeLayout'));
const NotAuthorized = lazy(() => import('components/Pages/Errors/NotAuthorized'));
const NotFound = lazy(() => import('components/Pages/Errors/NotFound'));
const Referencing = lazy(() => import('components/Pages/Referencing/ReferencingLayout'));
const SimulationsRequest = lazy(() => import('components/Pages/Simulations/RequestStep/SimulationsRequestLayout'));
const SimulationsValidation = lazy(() => import('components/Pages/Simulations/ValidationStep/SimulationValidationLayout'));
const SimulationsValidated = lazy(() => import('components/Pages/Simulations/ValidatedStep/SimulationValidatedLayout'));
const SimulationListLayout = lazy(() => import('components/Pages/Simulations/SimulationListLayout'));

export const AppRoutes: React.FC = () => {
    const [maintenanceConfig, setMaintenanceConfig] = useState<MaintenanceConfigurationResponse>({ isInMaintenance: false });
    const { state: { appContext, entities } } = useAppContext();
    const loggedUser = FranfinanceSelectors.getLoggedUser(appContext.loggedUserId, entities.franFinance);

    React.useEffect(() => {
        const getMaintenanceConfig = async () => {
            const maintenanceConfig = await AppContextsApi.getMaintenancePageToggle();
            setMaintenanceConfig(maintenanceConfig);
            if (window.location.href.includes('#') && !window.location.href.includes('/#access_token')) {
                window.location.href = window.location.href
                    .replace(/\/#\//, '/')
                    .replace(/\/#/, '/');
            }
        };
        getMaintenanceConfig();
    }, []);

    const showMaintenance = maintenanceConfig.isInMaintenance && maintenanceConfig.whitelistIcIds?.findIndex(x => x === loggedUser?.id) === -1;

    return showMaintenance ? <Layout><MaintenancePage endDate={maintenanceConfig.maintenanceEndDate} /></Layout>
        : <Layout>
            <Suspense fallback={<div className="d-flex justify-content-center"><ServiceLoader /></div>}>
                <Routes>
                    <Route path={RoutePaths.Home.route} element={<HomeLayout />} />
                    <Route path={RoutePaths.Invoices.route} element={<ProtectedRoute><Billings /></ProtectedRoute>} />
                    <Route path={RoutePaths.Company.Information.route} element={<ProtectedRoute><CompanyInformation /></ProtectedRoute>} />
                    <Route path={RoutePaths.Contracts.route} element={<ProtectedRoute><Contracts /></ProtectedRoute>} />
                    <Route path={RoutePaths.Contracts.Details.route} element={<ProtectedRoute><ContractDetails /></ProtectedRoute>} />
                    <Route path={RoutePaths.Contracts.Billings.route} element={<ProtectedRoute><ContractBillings /></ProtectedRoute>} />
                    <Route path={RoutePaths.EInvoices.route} element={<ProtectedRoute><EInvoices /></ProtectedRoute>} />
                    <Route path={RoutePaths.Referencing.route} element={<ProtectedRoute><Referencing /></ProtectedRoute>} />
                    <Route path={RoutePaths.Contracts.CustomerRequests.route} element={<ProtectedRoute><ContractCustomerRequests /></ProtectedRoute>} />
                    <Route path={RoutePaths.CustomerRequests.route} element={<ProtectedRoute><CustomerRequests /></ProtectedRoute>} />
                    <Route path={RoutePaths.CustomerRequests.Details.route} element={<ProtectedRoute><CustomerRequestDetails /></ProtectedRoute>} />
                    <Route path={RoutePaths.Simulations.Request.route} element={<ProtectedRoute CheckFranfinanceAuthorization><SimulationsRequest /></ProtectedRoute>} />
                    <Route path={RoutePaths.Simulations.Validation.route} element={<ProtectedRoute CheckFranfinanceAuthorization><SimulationsValidation /></ProtectedRoute>} />
                    <Route path={RoutePaths.Simulations.Validated.route} element={<ProtectedRoute CheckFranfinanceAuthorization><SimulationsValidated /></ProtectedRoute>} />
                    <Route path={RoutePaths.Simulations.FinancialRequest.route} element={<ProtectedRoute CheckFranfinanceAuthorization><SimulationListLayout /></ProtectedRoute>} />
                    <Route path={RoutePaths.Errors.NotAuthorized.route} element={<NotAuthorized />} />
                    <Route path={RoutePaths.Errors.NotAuthorizedContactUs.route} element={<NotAuthorized isDisplayTryAgain={false} />} />
                    <Route path={RoutePaths.Errors.NotAuthorizedInternet.route} element={<InternetNotAuthorized />} />
                    <Route path={RoutePaths.Errors.NotFound.route} element={<NotFound />} />
                </Routes >
            </Suspense >
        </Layout >;
};